import React from 'react'
import { Container, Flex, Card, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import StackTitle from '@solid-ui-components/StackTitle'
import ListItem from '@solid-ui-components/ListItem'
import FlexImage from '@solid-ui-components/FlexImage'
import FlexContent from '@solid-ui-components/FlexContent'
import FlexOverlapFade from '@solid-ui-components/FlexOverlapFade'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
const FeaturesWithPhotoBlock02 = ({
  content: { kicker, title, text_1, text_2, images, collection, buttons },
  reverse,
  imageEffect,
  threshold
}) => (
  <Container sx={{ position: `relative` }}>
    <Flex
      sx={{
        alignItems: [null, `center`],
        flexDirection: [
          reverse ? `column-reverse` : `column`,
          reverse ? `row-reverse` : `row`
        ],
        mx: [null, null, null, -4]
      }}
    >
      <FlexImage reverse={reverse}>
        <ContentImages content={{ images }} reverse={reverse} />
      </FlexImage>
      <FlexContent reverse={reverse}>
        <StackTitle
          kicker={kicker}
          title={title}
          lines={[text_1, text_2]}
          align={[`center`, `left`]}
          space={0}
        />
        {collection && (
          <>
            <Divider space={3} />
            <Flex sx={{ flexWrap: `wrap`, maxWidth: 500, m: -2 }}>
              {collection.map((props, index) => (
                <Reveal
                  key={`item-${index}`}
                  effect='fadeInPop'
                  delay={1 + 0.2 * (index + 1)}
                  duration={0.5}
                  css={css({ flexBasis: [`1`, `1/2`] })}
                >
                  <Card py='3' m='2'>
                    <ListItem {...props} compact middle p='2' />
                  </Card>
                </Reveal>
              ))}
            </Flex>
          </>
        )}
        <ContentButtons content={buttons} space={3} />
      </FlexContent>
    </Flex>
    <FlexOverlapFade direction={reverse ? 'ltr' : 'rtl'} />
  </Container>
)

export default WithDefaultContent(FeaturesWithPhotoBlock02)
