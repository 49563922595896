import React from 'react'
import { Flex, Box } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import StackTitle from '@solid-ui-components/StackTitle'
import ContentButtons from '@solid-ui-components/ContentButtons'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentMap from '@solid-ui-components/ContentMap'
import Modal from '@solid-ui-components/Modal'
import ContentForm from '@solid-ui-components/ContentForm'
import ListItem from '@solid-ui-components/ListItem'
import getStripe from "../../../../../../site/src/stripe"

const ModalBlock02 = ({
  content: {
    identifier,
    kicker,
    title,
    text_1,
    text_2,
    images,
    map,
    collection,
    form,
    buttons
  },
  reverse
}) => {
  const hasMedia = images || map

  const redirectToCheckout = async event => {
    event.preventDefault()

    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: 'price_1Io5zPFOHsn8igw2gCAezgQd', quantity: 1 }],
      successUrl: `${window.location.origin}/success?session_id={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${window.location.origin}/cancel`,
    })
    console.log(error)
    if (error) {
      console.warn("Error:", error)
    }
  }
  return (
    <Modal
      id={identifier}
      contentStyles={{ maxWidth: hasMedia ? `narrow` : 500, p: 0 }}
    >
      <Flex
        sx={{
          alignItems: `stretch`,
          flexDirection: [
            reverse ? `column-reverse` : `column`,
            reverse ? `row-reverse` : `row`
          ]
        }}
      >
        {hasMedia && (
          <Box
            sx={{
              display: [`none`, null, `block`],
              minWidth: [null, null, null, 500],
              flex: 1,
              position: `relative`,
              borderRadius: reverse ? `right` : `left`,
              overflow: `hidden`
            }}
          >
            {images && (
              <ContentImages
                content={{ images }}
                imageFit='cover'
                height='100%'
                reverse={reverse}
              />
            )}
            {map && (
              <Reveal
                effect={reverse ? 'fadeInRight' : 'fadeInLeft'}
                style={{ width: `100%`, height: `100%` }}
              >
                <ContentMap content={map} />
              </Reveal>
            )}
          </Box>
        )}
        <Box sx={{ flex: 1, p: 5 }}>
          <StackTitle
            kicker={kicker}
            title={title}
            lines={[text_1, text_2]}
            space={3}
          />
          {collection?.map((props, index) => (
            <ListItem key={`item-${index}`} {...props} compact />
          ))}
          {form && <ContentForm form={form} id={`form.${identifier}`} />}
          <ContentButtons
            content={buttons}
            space={3}
            wrapperStyles={{ textAlign: `center` }}
            onClick={redirectToCheckout}
          />
        </Box>
      </Flex>
    </Modal>
  )
}

export default ModalBlock02
