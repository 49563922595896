import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Tabs from '@solid-ui-components/Tabs'
import Hero from '@solid-ui-blocks/Hero/Block01'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import FeatureThree from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import Process from '@solid-ui-blocks/Features/Block03'
import Testimonials from '@solid-ui-blocks/Testimonials/Block03'
import Faq from '@solid-ui-blocks/Faq/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '@flexiblocks/templates/saas-v2/_theme'
import styles from '@flexiblocks/templates/saas-v2/_index.styles'

const IndexPage = props => {
  const { allBlockContent } = useStaticQuery(query)
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Rhodium11' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} menuJustify='space-between' />
      <Divider space='5' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='wide' sx={styles.tabsContainer}>
        <Tabs space={4}>
          <FeatureTwo content={content['tab-feature-two']} />
          <FeatureThree content={content['tab-feature-three']} />
          <FeatureOne content={content['tab-feature-one']} />
        </Tabs>
        <Divider space='5' />
      </Container>
      <Container variant='full' sx={styles.socialProofContainer}>
      <Divider space='5' />
      <Divider space='5' />
      <Process content={content['process']} />
      <Divider space='5' />
      <Divider space='5' />
      </Container>
      <Container variant='full' sx={styles.featuresContainer}>
        <Divider space='5' />
        <Divider space='5' />
        <Faq content={content['faq']} />
        <Divider space='5' />
      </Container>
      <Container variant='full'>
        <Divider space='5' />
        <Divider space='5' />
        <Testimonials content={content['testimonials']} />
        <Divider space='5' />
      </Container>
      {/* <Divider space='5' />
      <Divider space='5' /> */}
      {/* <Pricing content={content['pricing']} /> */}
      {/*<Divider space='5' />
      <Container variant='narrow'>
        <Features content={content['features']} />
      </Container>
      <Divider space='5' />
      <Divider space='5' /> */}
      <Footer content={content['footer']} />
    </Layout>
  )
}

const query = graphql`
  query siteIndexBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/index", "site/shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
