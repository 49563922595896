import React from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { Container, Flex, Box, css } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentContainer from '@solid-ui-components/ContentContainer'
import { ImQuotesRight } from 'react-icons/im'
import { AiFillStar } from 'react-icons/ai'
import Reveal from '@solid-ui-components/Reveal'
import StackTitle from '@solid-ui-components/StackTitle'
import Tabs from '@solid-ui-components/Tabs'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import getImage from '@solid-ui-components/utils/getImage'

const styles = {
  avatar: {
    bg: `omegaLighter`,
    borderTopColor: `omegaLighter`,
    borderTopWidth: `xl`,
    borderTopStyle: `solid`,
    borderRadius: `lg`,
    boxSizing: `content-box`
  }
}

const TestimonialsBlock03 = ({
  content: { kicker, title, text_1, text_2, collection, buttons },
  reverse
}) => (
  <Container style={{minHeight:"550px"}}>
    <Flex
      sx={{
        alignItems: `flex-start`,
        flexDirection: [
          reverse ? `column-reverse` : `column`,
          null,
          null,
          reverse ? `row-reverse` : `row`
        ],
        mx: [null, null, null, -4]
      }}
    >
      <Box
        sx={{
          flexBasis: `1/2`,
          mx: [null, null, null, 4],
          [reverse ? 'mt' : 'mb']: [5, null, null, 0]
        }}
      >
        <Tabs variant='dots' position='bottom' space={3} autoplay>
          {collection?.map(
            ({ container, kicker, title, description, avatar }, index) => (
              <ContentContainer
                content={container}
                variant='cards.paper'
                key={`item-${index}`}
                sx={{ position: `relative` }}
              >
                <Flex
                  sx={{
                    alignItems: `center`,
                    position: `relative`,
                    flexWrap: `wrap`,
                    zIndex: 1
                  }}
                >
                  <Box sx={{ width: 150 }}>
                    <Reveal effect='fadeInRotate'>
                      <Img
                        image={getImage(avatar?.src)}
                        alt={avatar?.alt}
                        css={css(styles.avatar)}
                      />
                    </Reveal>
                  </Box>
                  <Box sx={{ flex: [`100%`, 1], ml: [0, 4] }}>
                    <Reveal effect='fadeInUp'>
                      <ContentText content={kicker} variant='h5' />
                      <ContentText content={title} variant='h6' />
                      <ContentText content={description} variant='p' />
                      {Array.from({ length: 5 }, (_, i) => (
                        <Reveal
                          key={`item-${i}`}
                          effect='fadeInRotate'
                          delay={0.2 * (i + 1)}
                          css={css({ display: `inline-block` })}
                        >
                          <AiFillStar
                            css={css({ color: `beta`, size: `icon.xs` })}
                          />
                        </Reveal>
                      ))}
                    </Reveal>
                  </Box>
                </Flex>
                <ImQuotesRight
                  css={css({
                    size: `30%`,
                    color: `omegaLighter`,
                    position: `absolute`,
                    transform: `translate(0, -20%)`,
                    bottom: 0,
                    right: 0
                  })}
                />
              </ContentContainer>
            )
          )}
        </Tabs>
      </Box>
      <Box
        sx={{
          flexBasis: `1/2`,
          mx: [null, null, null, 4],
          textAlign: [`center`, null, null, `left`]
        }}
      >
        <Reveal effect={reverse ? 'fadeInLeft' : 'fadeInRight'} threshold={0.7}>
          <StackTitle
            kicker={kicker}
            title={title}
            lines={[text_1, text_2]}
            space={0}
            align={null}
          />
          <ContentButtons content={buttons} space={3} />
        </Reveal>
      </Box>
    </Flex>
  </Container>
)

export default WithDefaultContent(TestimonialsBlock03)
