import { loadStripe } from '@stripe/stripe-js'

/* 
Example test key
pk_test_kBfANfVuSImAls4xi2uC0nmy
*/

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_live_51IaE1qFOHsn8igw2SNJqYPCR4ZfyhVuD897kspzzUnywmWnUQPUODcAXgxT6TqYZ5QuR1jCUV7foohof9JtBQ9q300uV2rJfbP")
  }
  return stripePromise
}

export default getStripe